@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: chalk;
  src: url(/public/assets/font/ChalkDust.ttf);
}

body,
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  background-color: #06002f;
  overflow-x: hidden;
  scrollbar-color: rebeccapurple transparent;
  scrollbar-width: thin;
}
.carousal-section::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.box-inner {
  scroll-behavior: smooth;
}

/* modal */
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* opacity: 0; */
  /* pointer-events: none; */
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
}

/* .modal-container.show {
  pointer-events: auto;
  opacity: 1;
} */

.modal {
  /* background-color: #fff; */
  background-color: rgba(6, 0, 47, 1);
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 600px;
  height: 200px;
  max-width: 100%;
  border: 1px solid #2e3192;
  /* border: 1px solid #fe01ac; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* scroller */

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: #06002f;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2e3192;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #662d91;
}

/* slick-slider */

.slick-prev,
.slick-next {
  top: 40%;
  width: 30px;
  height: 60px;
}

ul {
  padding-left: 20px;
}

ul li {
  color: #fff;
  list-style-type: disc;
}

.videoContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  transition: transform 1s ease-in-out;
  z-index: 50;
  height: 400px;
  width: 640px;
}

.closeButton {
  color: #fff;
  transform: translate(0px, -0px);
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 1280px) {
  .slick-prev,
  .slick-next {
    top: 40%;
    width: 20px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .videoContainer {
    height: 200px;
    width: 365px;
  }

  .closeButton {
    transform: translate(-10px, -20px);
  }
}

.button-bg {
  height: 90px;
}

.coming-soon {
  /* font-size: 24px;
  font-weight: 700; */
  padding: 40px 0;
}

.coming-text {
  transform: translateY(45px);
  height: 13vh;
}

@media (max-height: 900px) {
  .coming-soon {
    transform: translateY(-30px);
    font-weight: 600;
  }
}

@media (max-height: 900px) {
  .coming-soon {
    font-weight: 600;
    font-size: 18px;
  }
}

@media (max-height: 780px) {
  .coming-soon {
    transform: translateY(-20px);
  }
}

@media (max-height: 760px) {
  .coming-soon {
    transform: translateY(-10px);
  }
}

@media (max-height: 700px) {
  .coming-soon {
    transform: translateY(-30px);
  }
  .white-border {
    /* visibility: hidden; */
    transform: translateY(-60px);
  }
  .slashn-img {
    width: 50% !important;
    transform: translateY(-60px);
  }

  .button-bg {
    height: 80px;
  }
}

@media (max-height: 580px) {
  .coming-soon {
    transform: translateY(-10px);
  }
}

@media (max-height: 515px) {
  .coming-soon {
    transform: translateY(0px);
  }
}
